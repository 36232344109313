import { AxiosInstanceWithXAuthToken } from "./api";
/**
 * @function  getAllDoctors
 * @description get all doctors list based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllDoctors = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/doctors${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function  getAllDoctorsCount
 * @description get all doctors count based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllDoctorsCount = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/doctors/count${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function postCreateDoctor
 * @description post information of  an doctor to create new cient
 * @param {Function} callback this function used for return response data from api
 * @param {Object} body includes information values of new doctor
 * @param {string} params this value used for parameters of api call
 */
export const postCreateDoctor = (callback, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/doctors${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function putEditDoctor
 * @description put new information of target doctor to update information
 * @param {Function} callback this function used for return response data from api
 * @param {string} userId target doctor id value
 * @param {Object} body includes information values of target doctor
 * @param {string} params this value used for parameters of api call
 */
export const putEditDoctor = (callback, userId, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .put(`/api/v1/admins/doctors/${userId}${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
