import { AxiosInstanceWithXAuthToken } from "./api";
/**
 * @function getAllClients
 * @description get all clients list based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllClients = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/sign-in";
      }
      callback(false, err);
    });
};
/**
 * @function getAllClientsCount
 * @description get all clients count based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllClientsCount = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/count${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/sign-in";
      }
      callback(false, err);
    });
};
/**
 * @function getClientDetails
 * @description get an client detail information based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} id client id value
 * @param {string} params this value used for parameters of api call
 */
export const getClientDetails = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function getClientAddresses
 * @description get an client addresses information based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} id client id value
 * @param {string} params this value used for parameters of api call
 */
export const getClientAddresses = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/addresses/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function postCreateClient
 * @description post information of  an client to create new cient
 * @param {Function} callback this function used for return response data from api
 * @param {Object} body includes information values of new client
 * @param {string} params this value used for parameters of api call
 */
export const postCreateClient = (callback, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/clients${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function putEditClient
 * @description put new information of target client to update information
 * @param {Function} callback this function used for return response data from api
 * @param {string} userId target client id value
 * @param {Object} body includes information values of target client
 * @param {string} params this value used for parameters of api call
 */
export const putEditClient = (callback, userId, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .put(`/api/v1/admins/clients/${userId}${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function postCreateClientAddress
 * @description post an address for a client to create new cient address
 * @param {Function} callback this function used for return response data from api
 * @param {Object} body includes information values of new client address
 * @param {string} params this value used for parameters of api call
 */
export const postCreateClientAddress = (callback, userId, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/clients/addresses/${userId}${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      console.log(err);
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
