// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect } from "react";
// import { useMaterialUIController } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useMaterialUIController,
  setPersonInfo,
  setNurseTab,
  // setScrollInDetail,
  setCircularProgress,
} from "context";
// import Certificate from "./Certificate";
import NurseDetailNavbar from "examples/Navbars/DashboardNavbar";
import { getNurseDetails } from "api/nurses";
import UserProfile from "./UserProfile";
import Serums from "./Serums";
import Transactions from "./Transactions";
import Overview from "./Overview";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { personInfo, nurseTab, refreshNurses } = controller;
  const setTabFunction = (num) => {
    setNurseTab(dispatch, num);
  };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getNurseDetails((isOk, data) => {
      if (!isOk) {
        console.log("get nurse details from api is not working!");
        navigate("/nurses?page=1");
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setPersonInfo(dispatch, data.data);
      }
    }, id);
  }, [refreshNurses]);
  const renderInnerTable = () => {
    if (nurseTab === 0) return <Overview id={id} />;
    if (nurseTab === 1) return <Transactions id={id} />;
    if (nurseTab === 2) return <Serums id={id} />;
    return "";
  };
  useEffect(() => {
    navigate(`/nurses/${id}`);
  }, [nurseTab]);
  if (Object.keys(personInfo).length === 0) return <div />;
  return (
    <DashboardLayout>
      <NurseDetailNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <UserProfile personInfo={personInfo} tab={nurseTab} setTab={setTabFunction} />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {nurseTab === 0 ? (
              renderInnerTable()
            ) : (
              <Card className="mt-4">{renderInnerTable()}</Card>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
