import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/orders/data/tableData";
import { useEffect, useState } from "react";
import { getAllOrders, getAllOrdersCount } from "api/orders";
import {
  setOrdersData,
  useMaterialUIController,
  setAlertColor,
  setAlertText,
  setAlertOpen,
  setCircularProgress,
} from "context";
import { Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "./modal";
import StatusModal from "./statusModal";

/**
 * @component OrdersTables
 * @description Component for Orders Tables.
 * @returns {ReactNode} render a ui for Orders Tables.
 * @property {number} pageNumber set local pageNumber value.
 * @property {number} allPages set local allPages value.
 * @property {number} itemsPerPage items Per Page value.
 * @property {Object} columns table columns value.
 * @property {Object} rows table rows value.
 */
function OrdersTables() {
  const { columns, rows } = authorsTableData();
  const [controller, dispatch] = useMaterialUIController();
  const { itemsPerPage } = controller;
  const [pageNumber, setPageNumber] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    let url = `/orders`;
    url += `?page=${pageNumber}`;
    navigate(url);
  }, [pageNumber]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setPageNumber(1);
    else setPageNumber(parseInt(paramsObj.page, 10));
    setCircularProgress(dispatch, true);
    getAllOrdersCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give orders count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        if (res.data !== 0) {
          setAllPages(Math.ceil(res.data / itemsPerPage));
        }
        let param = "";
        param += `?skip=${(parseInt(paramsObj.page, 10) - 1) * itemsPerPage}&limit=${itemsPerPage}`;
        getAllOrders((isOk2, res2) => {
          if (!isOk2) {
            setAlertColor(dispatch, "error");
            setAlertText(dispatch, "error in giving orders list");
            setAlertOpen(dispatch, true);
            setCircularProgress(dispatch, false);
          } else {
            setOrdersData(dispatch, res2.data);
            setCircularProgress(dispatch, false);
          }
        }, param);
      }
    });
  }, [pathname, window.location.search]);
  const handlePaginationChange = (event, value) => {
    let url = "";
    url += "/orders";
    url += `?page=${value}`;
    navigate(url);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal />
      <StatusModal />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  orders Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={pageNumber}
          onChange={handlePaginationChange}
          count={allPages}
          color="mainColor"
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default OrdersTables;
