import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/**
 * @component dataTable
 * @description this component for render tables information
 * @returns {ReactNode} render a ui for Admins Table.
 * @property {Object} adimns admin list data.
 */
export default function data() {
  const [controller] = useMaterialUIController();
  const { admins } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(admins);
  }, [admins]);
  /**
   * @function getFullName
   * @description this function for return valid full name
   * @param {string} first first name of Auther
   * @param {string} last last name of Auther
   * @returns {string} full name
   */
  const getFullName = (first, last) => {
    if (first === null && last === null) return "Not defined by User";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  /**
   * @function Author
   * @description this function for return valid full name
   * @param {string} image image of Auther
   * @param {string} name name of Auther
   * @param {string} email email of Auther
   * @param {string} id id of Auther
   * @returns {string} full name
   */
  function Author({ image, name, email, id }) {
    return (
      <Link
        to="/"
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <MDTypography
          style={{ minWidth: "50px" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          #{id}
        </MDTypography>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </Link>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      name: (
        <Author
          image={value.avatar || "assets/images/person.png"}
          name={getFullName(value.firstName, value.lastName)}
          email={value.email}
          id={value.id}
        />
      ),
      "access type": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.accessType}
        </MDBox>
      ),
      "user name": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.username}
        </MDBox>
      ),
      status: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.status}
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "name", accessor: "name", align: "left" },
      { Header: "user name", accessor: "user name", align: "center" },
      { Header: "access type", accessor: "access type", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
    ],

    rows: getRows(),
  };
}
