import Card from "@mui/material/Card";
import PropTypes from "prop-types";
// import person from "/assets/images/person.png";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import EditBtn from "./EditBtn";
import Navbar from "./Navbar";

function UserProfile({ personInfo, tab, setTab }) {
  const getBirthMonth = (join) => {
    const myJoin = join.split("T")[0];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = myJoin.split("-")[2];
    const month = parseInt(myJoin.split("-")[1], 10);
    return `${day} ${months[month - 1]}`;
  };
  return (
    <Card className="py-4 px-8">
      <div className="h-[200px] w-[100%]">
        <div className="h-[80%] flex flex-row">
          <MDBox className="w-[160px] h-[160px]">
            <img
              className="w-[100%] h-[100%] rounded object-cover"
              src={personInfo?.avatars ? personInfo?.avatars?.large : "/assets/images/person.png"}
              alt={`${personInfo?.first_name}'sPhoto`}
            />
          </MDBox>
          <div className="w-[80%] px-4">
            <div className="flex flex-row">
              <div>
                <div className="flex flex-row items-center">
                  <MDBox color="text" className="font-bold text-[20px]">
                    {!personInfo.firstName && !personInfo.lastName
                      ? "Not defined by User"
                      : `${personInfo.firstName} ${personInfo.lastName}`}
                  </MDBox>
                </div>
                <div className="flex mt-1">
                  <div className="flex items-center justify-center">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        fingerprint
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.id}</div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        login_icon
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">
                      {personInfo.createdAt ? getBirthMonth(personInfo.createdAt) : "not found"}
                    </div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        call
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.phone}</div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        location_on_icon
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">
                      {personInfo.state} / {personInfo.city}
                    </div>
                  </div>
                </div>
              </div>
              <EditBtn personInfo={personInfo} />
            </div>
          </div>
        </div>
        <div className="h-[20%] mt-2 flex items-center">
          <Navbar tab={tab} setTab={setTab} />
        </div>
      </div>
    </Card>
  );
}
UserProfile.propTypes = {
  personInfo: PropTypes.object,
  tab: PropTypes.number,
  setTab: PropTypes.func,
};
UserProfile.defaultProps = {
  personInfo: {},
  tab: 1,
  setTab: () => {},
};
export default UserProfile;
