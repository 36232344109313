import { Icon } from "@mui/material";
import { getAllTransactions, putATransaction } from "api/transactions";

import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import { setCircularProgress, setClientTransactions, useMaterialUIController } from "context";

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { clientTransactions, itemsPerPage } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(clientTransactions);
  }, [clientTransactions]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let clientId = pathname.split("/");
  clientId = clientId[clientId.length - 1];
  clientId = clientId.split("?");
  [clientId] = clientId;

  const refreshTransaction = (id) => {
    setCircularProgress(dispatch, true);
    putATransaction((isOk, res) => {
      if (false) console.log(res);
      if (!isOk) {
        console.log("get client details from api is not working!");
        setCircularProgress(dispatch, false);
      } else {
        const params = new URLSearchParams(window.location.search);
        const paramsObj = Array.from(params.keys()).reduce(
          (acc, val) => ({ ...acc, [val]: params.get(val) }),
          {}
        );
        let param = "";
        param += `?clientId=${clientId}&skip=${
          (parseInt(paramsObj.page, 10) - 1) * itemsPerPage || 0
        }&limit=${itemsPerPage}`;
        getAllTransactions((isOk2, data2) => {
          if (!isOk2) {
            console.log("get client details from api is not working!");
            navigate("/clients?page=1");
            setCircularProgress(dispatch, false);
          } else {
            setCircularProgress(dispatch, false);
            setClientTransactions(dispatch, data2.data);
          }
        }, param);
      }
    }, id);
  };

  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      "update status": (
        <Icon onClick={() => refreshTransaction(value.id)} style={{ cursor: "pointer" }}>
          refresh
        </Icon>
      ),
      "transaction id": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.id}
        </MDBox>
      ),
      "payment id": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.paymentId}
        </MDBox>
      ),
      status: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.status}
        </MDBox>
      ),
      "pay time": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          <div>{value.paymentCreated.split("T")[0]}</div>
          <div style={{ fontSize: "12px" }}>{value.paymentCreated.split("T")[1].split(".")[0]}</div>
        </MDBox>
      ),
      amount: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.amount / 100}$
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "update status", accessor: "update status", align: "center" },
      { Header: "transaction id", accessor: "transaction id", align: "center" },
      { Header: "payment id", accessor: "payment id", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "pay time", accessor: "pay time", align: "center" },
      { Header: "amount", accessor: "amount", align: "center" },
    ],

    rows: getRows(),
  };
}
