import { useMaterialUIController } from "context";
import DrawerRightForCreateClient from "./DrawerForCreateClient";
import DrawerRightForEditClient from "./DrawerForEditClient";
import DrawerRightForCreateDoctor from "./DrawerForCreateDoctor";
import DrawerRightForEditDoctor from "./DrawerForEditDoctor";
import DrawerRightForCreateNurse from "./DrawerForCreateNurse";
import DrawerRightForEditNurse from "./DrawerForEditNurse";
import DrawerRightForCreateProduct from "./DrawerForCreateProduct";
import DrawerRightForEditProduct from "./DrawerForEditProduct";
import DrawerRightForAddClientAddress from "./DrawerForAddClientAddress";

function DrawerRight() {
  const [controller] = useMaterialUIController();
  const { openDrawerReason } = controller;
  if (openDrawerReason === "createClient") return <DrawerRightForCreateClient />;
  if (openDrawerReason === "editClient") return <DrawerRightForEditClient />;
  if (openDrawerReason === "createDoctor") return <DrawerRightForCreateDoctor />;
  if (openDrawerReason === "editDoctor") return <DrawerRightForEditDoctor />;
  if (openDrawerReason === "createNurse") return <DrawerRightForCreateNurse />;
  if (openDrawerReason === "editNurse") return <DrawerRightForEditNurse />;
  if (openDrawerReason === "createProduct") return <DrawerRightForCreateProduct />;
  if (openDrawerReason === "editProduct") return <DrawerRightForEditProduct />;
  if (openDrawerReason === "addClientAddress") return <DrawerRightForAddClientAddress />;
}
export default DrawerRight;
