import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useState } from "react";
import { getAllProducsCount } from "api/products";
import {
  setAlertColor,
  setCircularProgress,
  useMaterialUIController,
  setAlertText,
  setAlertOpen,
} from "context";
import { getAllAdminsCount } from "api/admins";
import { getAllClientsCount } from "api/clients";
import { getAllOrdersCount } from "api/orders";
import { Link } from "react-router-dom";

/**
 * @component Dashboard
 * @description Component for Dashboard.
 * @returns {ReactNode} render a ui for Dashboard.
 * @property {number} productsCount set local products Count value.
 * @property {number} adminsCount set local admins Count value.
 * @property {number} clientsCount items Per clients Count value.
 * @property {number} ordersCount items Per orders Count value.
 */
function Dashboard() {
  const [productsCount, setProductsCount] = useState(0);
  const [adminsCount, setAdminsCount] = useState(0);
  const [clientsCount, setClientsCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAllProducsCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give products count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setProductsCount(res.data);
        setCircularProgress(dispatch, false);
      }
    });
    getAllAdminsCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give admins count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setAdminsCount(res.data);
        setCircularProgress(dispatch, false);
      }
    });
    getAllClientsCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give clients count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setClientsCount(res.data);
        setCircularProgress(dispatch, false);
      }
    });
    getAllOrdersCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give orders count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setOrdersCount(res.data);
        setCircularProgress(dispatch, false);
      }
    });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/orders">
                <ComplexStatisticsCard
                  icon="book_online"
                  title="Orders"
                  count={ordersCount}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/clients">
                <ComplexStatisticsCard
                  color="dark"
                  icon="group"
                  title="Clients"
                  count={clientsCount}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/products">
                <ComplexStatisticsCard
                  icon="vaccines"
                  title="products"
                  count={productsCount}
                  percentage={{
                    color: "warning",
                    amount: "-3%",
                    label: "than last month",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/admins">
                <ComplexStatisticsCard
                  color="dark"
                  icon="supervised_user_circle"
                  title="Admins"
                  count={adminsCount}
                  percentage={{
                    color: "success",
                    amount: "+55%",
                    label: "than lask week",
                  }}
                />
              </Link>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
