import PropTypes from "prop-types";

/**
 * @component Navbar
 * @description render client detail navbar
 * @param {number} tab client detail active tab
 * @param {Function} setTab set client detail active tab
 */
function Navbar({ tab, setTab }) {
  const tabList = [
    { title: "Overview", id: 0 },
    { title: "Addresses", id: 1 },
    { title: "Orders", id: 2 },
    { title: "Transactions", id: 3 },
  ];
  return (
    <div className="flex flex-row">
      {tabList.map(({ title, id }) => (
        <div
          role="presentation"
          key={id}
          onClick={() => setTab(id)}
          className={`font-bold ${
            id === tab ? "border-b-2 border-[#095F6C] text-[#095F6C]" : "text-[#919090]"
          } hover:border-b-2 hover:border-[#095F6C] cursor-pointer mr-4 text-[16px]`}
        >
          {title}
        </div>
      ))}
    </div>
  );
}
Navbar.propTypes = {
  tab: PropTypes.number,
  setTab: PropTypes.func,
};
Navbar.defaultProps = {
  tab: 1,
  setTab: () => {},
};
export default Navbar;
