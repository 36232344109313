import { AxiosInstanceWithXAuthToken } from "./api";
/**
 * @function getAllNurses
 * @description get all nurses list based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllNurses = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/nurses${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function getAllNursesCount
 * @description get all nurses count based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllNursesCount = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/nurses/count${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function postCreateNurse
 * @description post information of  an nurse to create new cient
 * @param {Function} callback this function used for return response data from api
 * @param {Object} body includes information values of new nurse
 * @param {string} params this value used for parameters of api call
 */
export const postCreateNurse = (callback, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/nurses${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function putEditNurse
 * @description put new information of target nurse to update information
 * @param {Function} callback this function used for return response data from api
 * @param {string} userId target nurse id value
 * @param {Object} body includes information values of target nurse
 * @param {string} params this value used for parameters of api call
 */
export const putEditNurse = (callback, userId, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .put(`/api/v1/admins/nurses/${userId}${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function getNurseDetails
 * @description get an nurse detail information based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} id nurse id value
 * @param {string} params this value used for parameters of api call
 */
export const getNurseDetails = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/nurses/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
