import * as yup from "yup";

/**
 * @function SignInSchema
 * @description create an model for sign in that verify body of sign in information.
 * @returns an yup Object
 */
const SignInSchema = yup.object().shape({
  username: yup.string("user name must be string").required("user name is required"),
  password: yup.string("password must be string").required("password is required"),
});

export default SignInSchema;
