/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Products from "layouts/products";
import Admins from "layouts/admins";
import Clients from "layouts/clients";
import Doctors from "layouts/doctors";
import Nurses from "layouts/nurses";
// import Docs from "layouts/docs";
import Orders from "layouts/orders";
import ClientDetail from "layouts/clientDetails";
import NurseDetail from "layouts/nurseDetails";
import Transactions from "layouts/transactions";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "noSideNav",
    name: "noSideNav",
    key: "noSideNav",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/clients/:id",
    component: <ClientDetail />,
  },
  // {
  //   type: "noSideNav",
  //   name: "noSideNav",
  //   key: "noSideNav",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/docs",
  //   component: <Docs />,
  // },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">book_online</Icon>,
    route: "/orders",
    component: <Orders />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/clients",
    component: <Clients />,
  },
  {
    type: "collapse",
    name: "Doctors",
    key: "doctors",
    icon: <Icon fontSize="small">person_3</Icon>,
    route: "/doctors",
    component: <Doctors />,
  },
  {
    type: "collapse",
    name: "Nurses",
    key: "nurses",
    icon: <Icon fontSize="small">medication_icon</Icon>,
    route: "/nurses",
    component: <Nurses />,
  },
  {
    type: "noSideNav",
    name: "noSideNav",
    key: "noSideNav",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/nurses/:id",
    component: <NurseDetail />,
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    icon: <Icon fontSize="small">currency_exchange</Icon>,
    route: "/transactions",
    component: <Transactions />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">vaccines</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    type: "collapse",
    name: "Admins",
    key: "admins",
    icon: <Icon fontSize="small">supervised_user_circle</Icon>,
    route: "/admins",
    component: <Admins />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "Logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
