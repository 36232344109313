// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect } from "react";
// import { useMaterialUIController } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useMaterialUIController,
  setPersonInfo,
  setClientTab,
  // setScrollInDetail,
  setCircularProgress,
} from "context";
// import Certificate from "./Certificate";
import ClientDetailNavbar from "examples/Navbars/DashboardNavbar";
import { getClientDetails } from "api/clients";
import UserProfile from "./UserProfile";
import Addresses from "./Addresses";
import Transactions from "./Transactions";
import Orders from "./Orders";
import Overview from "./Overview";

/**
 * @component Client Detail Page
 * @description this Component render client detail page.
 * @returns {ReactNode} render a ui for Admins Table.
 * @property {Object} personInfo clicked person info.
 * @property {number} clientTab client Tab value.
 * @property {boolean} refreshClients refreshClients value.
 * @property {string} id client id value.
 */
function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { personInfo, clientTab, refreshClients } = controller;
  const setTabFunction = (num) => {
    setClientTab(dispatch, num);
  };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getClientDetails((isOk, data) => {
      if (!isOk) {
        console.log("get client details from api is not working!");
        navigate("/clients?page=1");
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setPersonInfo(dispatch, data.data);
      }
    }, id);
  }, [refreshClients]);
  const renderInnerTable = () => {
    if (clientTab === 0) return <Overview id={id} />;
    if (clientTab === 1) return <Addresses id={id} />;
    if (clientTab === 2) return <Orders id={id} />;
    if (clientTab === 3) return <Transactions id={id} />;
    return "";
  };
  useEffect(() => {
    navigate(`/clients/${id}`);
  }, [clientTab]);
  // const handleScroll = () => {
  //   setScrollInDetail(dispatch, window.scrollY);
  // };
  // useEffect(() => {
  //   setScrollInDetail(dispatch, 0);
  //   window.addEventListener("scroll", handleScroll);
  // }, []);
  if (Object.keys(personInfo).length === 0) return <div />;
  return (
    <DashboardLayout>
      <ClientDetailNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <UserProfile personInfo={personInfo} tab={clientTab} setTab={setTabFunction} />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {clientTab === 0 ? (
              renderInnerTable()
            ) : (
              <Card className="mt-4">{renderInnerTable()}</Card>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
