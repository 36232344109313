import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  setOpenDrawerRight,
  useMaterialUIController,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setRefreshClients,
  setCircularProgress,
} from "context";
import MDInput from "components/MDInput";
import createUser from "model/clientModel";
import { putEditClient } from "api/clients";
import DrawerRightRoot from "./DrawerRightRoot";

/**
 * @component DrawerRightForEditClient
 * @description Component for drawer right to edit an client informations.
 * @returns {ReactNode} render a ui for drawer content
 * @property {number} name set local name value
 * @property {firstName} description set local description value
 * @property {lastName} status set local status value
 */
function DrawerRightForEditClient() {
  const [controller, dispatch] = useMaterialUIController();
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const { openDrawerRight, darkMode, refreshClients, editInfo } = controller;
  const [number, setNumber] = useState("+1");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  useEffect(() => {
    setFirstName(editInfo?.firstName);
    setLastName(editInfo?.lastName);
    setNumber(editInfo?.phone);
  }, [editInfo]);
  useEffect(() => {
    if (number.length < 2) setNumber("+1");
  }, [number]);
  const editUserBtnClicked = () => {
    const body = {
      firstName,
      lastName,
      phone: number,
    };
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    createUser
      .validate(body, { abortEarly: false })
      .then((valid) => {
        putEditClient(
          (isOk, data) => {
            if (!isOk) {
              console.log("edit Client information is not successful: ", data);
              setCircularProgress(dispatch, false);
              setAlertText(dispatch, data?.response?.data?.message);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "error");
            } else {
              setCircularProgress(dispatch, false);
              setRefreshClients(dispatch, !refreshClients);
              setLastName("");
              setFirstName("");
              setNumber("");
              setAlertText(dispatch, `edit ${editInfo?.firstName} information is successful!`);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "success");
            }
          },
          editInfo.id,
          valid
        );
      })
      .catch((err) => {
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      });
  };
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit {editInfo.firstName} {editInfo.lastName}&apos; information
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            id="firstNameInput"
            type="text"
            label="First Name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            id="lastNameInput"
            type="text"
            label="Last Name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            id="phoneNumberInput"
            type="text"
            label="Phone number"
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton onClick={() => editUserBtnClicked()} variant="gradient" color="info" fullWidth>
            Edit
          </MDButton>
        </MDBox>
      </MDBox>
    </DrawerRightRoot>
  );
}
export default DrawerRightForEditClient;
