// import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import {
  useMaterialUIController,
  setOrderItems,
  setOpenOrderItemsModal,
  setOpenUpdateOrderStatusModal,
  setOrderForUpdate,
  setClientIdInOrderModal,
} from "context";
import { useState, useEffect } from "react";
// import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { clientOrders, darkMode } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(clientOrders);
  }, [clientOrders]);
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#ECBE08";
      case "payed":
        return "#F44335";
      case "assigned-to-nurse":
        return "#05E7C4";
      case "no-response-by-client":
        return "#058BE9";
      case "canceled":
        return "#058BE9";
      case "done":
        return "#054701";
      default:
        return "black";
    }
  };
  const { pathname } = useLocation();
  let clientId = pathname.split("/");
  clientId = clientId[clientId.length - 1];
  clientId = clientId.split("?");
  [clientId] = clientId;
  const itemsClicked = (id) => {
    setOpenOrderItemsModal(dispatch, true);
    setOrderItems(dispatch, id);
  };
  const changeStatus = (order) => {
    setOpenUpdateOrderStatusModal(dispatch, true);
    setClientIdInOrderModal(dispatch, clientId);
    setOrderForUpdate(dispatch, order);
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      "order id": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.id}
        </MDBox>
      ),
      status: (
        <MDBox
          lineHeight={1}
          textAlign="center"
          style={{
            color: darkMode ? "white" : getStatusColor(value.status),
            backgroundColor: darkMode ? getStatusColor(value.status) : "white",
            border: `1px solid ${getStatusColor(value.status)}`,
            borderRadius: "10px",
            padding: "3px 10px",
          }}
        >
          {value.status !== "assigned-to-nurse" ? value.status : "assigned-to-nurse"}
        </MDBox>
      ),
      "total price": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.totalPrice / 100}$
        </MDBox>
      ),
      "visit at": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.visitAt.split("T")[0]}
        </MDBox>
      ),
      "created at": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.createdAt.split("T")[0]}
        </MDBox>
      ),
      address: (
        <MDBox color="text" lineHeight={2} textAlign="center" style={{ maxWidth: "300px" }}>
          <MDBox color="text" style={{ fontWeight: "700" }}>
            {value.address.country}/{value.address.city}
          </MDBox>
          <div style={{ fontSize: "10px", lineHeight: "15px" }}>
            {value.address.address || "no address"}
          </div>
        </MDBox>
      ),
      action: (
        <MDBox>
          <MDBox color="text">
            <Icon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => itemsClicked(value.id)}
            >
              visibility
            </Icon>
          </MDBox>
          <MDBox color="text">
            <Icon
              fontSize="small"
              style={{ cursor: "pointer" }}
              onClick={() => changeStatus(value)}
            >
              change_circle
            </Icon>
          </MDBox>
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "action", accessor: "action", align: "center" },
      { Header: "order id", accessor: "order id", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "total price", accessor: "total price", align: "center" },
      { Header: "visit at", accessor: "visit at", align: "center" },
      { Header: "created at", accessor: "created at", align: "center" },
      { Header: "address", accessor: "address", align: "center" },
    ],

    rows: getRows(),
  };
}
