/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Card, Grid, Pagination } from "@mui/material";
import { getAllTransactions, getAllTransactionsCount } from "api/transactions";
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import {
  useMaterialUIController,
  setCircularProgress,
  setClientTransactions,
  setAlertOpen,
  setAlertText,
  setAlertColor,
} from "context";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../orders/modal";
import authorsTableData from "./data/tableData";

/**
 * @component Transactions
 * @description Component for Transactions Table.
 * @returns {ReactNode} render a ui for Transactions Table.
 * @param {string} id client id value
 * @property {number} pageNumber set local pageNumber value.
 * @property {number} allPages set local allPages value.
 * @property {number} itemsPerPage items Per Page value.
 * @property {Object} columns table columns value.
 * @property {Object} rows table rows value.
 */
function Transactions({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const { itemsPerPage } = controller;
  const { pathname } = useLocation();
  useEffect(() => {
    let url = `/clients/${id}`;
    url += `?page=${pageNumber}`;
    navigate(url);
  }, [pageNumber]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setPageNumber(1);
    else setPageNumber(parseInt(paramsObj.page, 10));
    setCircularProgress(dispatch, true);
    getAllTransactionsCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give client transactions count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        if (res.data !== 0) {
          setAllPages(Math.ceil(res.data / itemsPerPage));
        }
        let param = "";
        param += `?clientId=${id}&skip=${
          (parseInt(paramsObj.page, 10) - 1) * itemsPerPage
        }&limit=${itemsPerPage}`;
        getAllTransactions((isOk2, res2) => {
          if (!isOk2) {
            setAlertColor(dispatch, "error");
            setAlertText(dispatch, "error in giving client transactions list");
            setAlertOpen(dispatch, true);
            setCircularProgress(dispatch, false);
          } else {
            setClientTransactions(dispatch, res2.data);
            setCircularProgress(dispatch, false);
          }
        }, param);
      }
    }, `?clientId=${id}`);
  }, [pathname, window.location.search]);
  const handlePaginationChange = (event, value) => {
    let url = "";
    url += `/clients/${id}`;
    url += `?page=${value}`;
    navigate(url);
  };
  return (
    <Grid container>
      <Modal />
      <Grid item xs={12}>
        <Card>
          <MDBox pt={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
            <Pagination
              page={pageNumber}
              onChange={handlePaginationChange}
              count={allPages}
              color="mainColor"
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Transactions;
