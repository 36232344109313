import { AxiosInstanceWithXAuthToken } from "./api";
/**
 * * @function getAllAdmins
 * @description get all admins list based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllAdmins = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * * @function getAllAdminsCount
 * @description get all admins count number based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllAdminsCount = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/count${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
