import { AxiosInstanceWithXAuthToken } from "./api";
/**
 * @function getAllOrders
 * @description get all orders list based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllOrders = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/orders${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function getAnOrderInfo
 * @description get an order detail based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} orderId order id value
 * @param {string} params this value used for parameters of api call
 */
export const getAnOrderInfo = (callback, orderId, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/orders/${orderId}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function getAllOrdersCount
 * @description get all orders count based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllOrdersCount = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/orders/count${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function putChangeOrderStatus
 * @description put change an order's Status based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} id order id value
 * @param {Object} body includes information values for change target orders status
 * @param {string} params this value used for parameters of api call
 */
export const putChangeOrderStatus = (callback, id, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .put(`/api/v1/admins/orders/status/${id}${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
