import { AxiosInstanceWithXAuthToken } from "./api";

/**
 * @function getAllTransactions
 * @description get all transactions list based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllTransactions = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/transactions${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};

/**
 * @function getClientAllTransactions
 * @description get a client's all transactions based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} id client id value
 * @param {string} params this value used for parameters of api call
 */
export const getClientAllTransactions = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/transactions/report/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};

/**
 * @function getAllTransactionsCount
 * @description get a all transactions count based on parameters
 * @param {Function} callback this function used for return response data from api
 * @param {string} params this value used for parameters of api call
 */
export const getAllTransactionsCount = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/transactions/count${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
/**
 * @function putATransaction
 * @description update status of target transaction
 * @param {Function} callback this function used for return response data from api
 * @param {string} id target transaction id value
 * @param {string} params this value used for parameters of api call
 */
export const putATransaction = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .put(`/api/v1/admins/transactions/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (!err) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location.pathname = "/authentication/sign-in";
        }
        callback(false, err);
      }
    });
};
