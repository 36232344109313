import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setOpenDrawerRight, setODR, setEditInfo } from "context";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { nurses } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(nurses);
  }, [nurses]);

  const getFullName = (first, last) => {
    if (first === null && last === null) return "Not defined by User";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  function Author({ name, email, id }) {
    return (
      <Link
        to={`/nurses/${id}`}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <MDTypography
          style={{ minWidth: "50px" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          #{id}
        </MDTypography>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </Link>
    );
  }
  Author.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    name: "",
    email: "",
    id: "",
  };
  const clickOnEdit = (info) => {
    setOpenDrawerRight(dispatch, true);
    setODR(dispatch, "editNurse");
    setEditInfo(dispatch, info);
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      name: (
        <Author
          name={getFullName(value.firstName, value.lastName)}
          email={value.phone}
          id={value.id}
        />
      ),
      "time join": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.createdAt?.split("T")[0]}
        </MDBox>
      ),
      phone: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value?.phone || "phone not added!"}
        </MDBox>
      ),
      address: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value?.state} / {value?.city}
        </MDBox>
      ),
      status: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value?.status}
        </MDBox>
      ),
      action: (
        <MDButton
          onClick={() => clickOnEdit(value)}
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{
            backgroundColor: "#095F6C",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
          }}
        >
          Edit
        </MDButton>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "name", accessor: "name", align: "left" },
      { Header: "time join", accessor: "time join", align: "center" },
      { Header: "phone", accessor: "phone", align: "center" },
      { Header: "address", accessor: "address", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}
