import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useMaterialUIController, setOpenDrawerRight, setODR, setEditInfo } from "context";
import { useState, useEffect } from "react";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { products } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(products);
  }, [products]);
  const clickOnEdit = (info) => {
    setOpenDrawerRight(dispatch, true);
    setODR(dispatch, "editProduct");
    setEditInfo(dispatch, info);
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      id: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          #{value.id}
        </MDBox>
      ),
      "product name": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.name}
        </MDBox>
      ),
      price: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {Math.round((value.price / 100) * 100) / 100}$
        </MDBox>
      ),
      status: (
        <MDButton
          ml={-1}
          color={value.status === "in-service" ? "success" : "dark"}
          variant="gradient"
          size="small"
        >
          {value.status}
        </MDButton>
      ),
      description: (
        <MDBox color="text" lineHeight={1.4} textAlign="start" style={{ width: "400px" }}>
          {value.description}
        </MDBox>
      ),
      action: (
        <MDButton
          onClick={() => clickOnEdit(value)}
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{
            backgroundColor: "#095F6C",
            color: "white",
            padding: "10px 30px",
            borderRadius: "10px",
          }}
        >
          Edit
        </MDButton>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "id", accessor: "id", align: "center" },
      { Header: "product name", accessor: "product name", align: "center" },
      { Header: "price", accessor: "price", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "description", accessor: "description", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: getRows(),
  };
}
