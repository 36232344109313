import * as yup from "yup";

/**
 * @function createProduct
 * @description create an model for product that verify body of products.
 * @returns an yup Object
 */
const createProduct = yup.object().shape({
  name: yup.string("name must be string").required("name is required"),
  description: yup.string("description must be string").required("description is required"),
  price: yup.number("price must be number type").required("price is required"),
  status: yup
    .mixed()
    .oneOf(["in-service", "deactivated", "out-of-service"], "error in status type")
    .required("status is required"),
});

export default createProduct;
