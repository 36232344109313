import * as yup from "yup";

/**
 * @function createNurse
 * @description create an model for nurse that verify body of nurses.
 * @returns an yup Object
 */
const createNurse = yup.object().shape({
  firstName: yup.string("first name must be string").required("first name is required"),
  lastName: yup.string("last name must be string").required("last name is required"),
  phone: yup.string("phone number must be number type").required("phone number is required"),
  state: yup.string("state must be number type").required("state is required"),
  city: yup.string("city must be number type").required("city is required"),
});

export default createNurse;
