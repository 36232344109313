import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useMaterialUIController, setOpenDrawerRight, setODR, setEditInfo } from "context";

/**
 * @component Navbar
 * @description render client detail navbar
 * @param {Object} personInfo client detail info
 */
function EditBtn({ personInfo }) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickOnEdit = (info) => {
    setAnchorEl(null);
    setOpenDrawerRight(dispatch, true);
    setODR(dispatch, "editClient");
    setEditInfo(dispatch, info);
  };
  return (
    <div
      onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
      className="ml-auto flex items-center justify-center"
    >
      <div className="bg-[#F9F9F9] cursor-pointer rounded h-[30px] w-[30px] pb-[10px] flex items-center justify-center">
        ...
      </div>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => clickOnEdit(personInfo)}>Edit</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
EditBtn.propTypes = {
  personInfo: PropTypes.object,
};
EditBtn.defaultProps = {
  personInfo: {},
};
export default EditBtn;
