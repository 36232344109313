/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import { getAllOrdersCount } from "api/orders";

import MDBox from "components/MDBox";
import {
  setAlertColor,
  setAlertOpen,
  setAlertText,
  setCircularProgress,
  useMaterialUIController,
  setNurseTab,
} from "context";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import React, { useEffect, useState } from "react";

function Oveview({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);

  const [sreumCount, setSreumCount] = useState(0);
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAllOrdersCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give orders count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setSreumCount(res.data);
        setCircularProgress(dispatch, false);
      }
    }, `?nurseId=${id}`);
  }, [window.location.search]);
  return (
    <MDBox py={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <div
              style={{ cursor: "pointer" }}
              role="presentation"
              onClick={() => setNurseTab(dispatch, 2)}
            >
              <ComplexStatisticsCard
                icon="book_online"
                title="Serums"
                count={sreumCount}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: `total numer of orders for this nurse is: ${sreumCount}`,
                }}
              />
            </div>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Oveview;
