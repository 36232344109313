import { Autocomplete, TextField } from "@mui/material";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function NurseSelection({
  setSelectedNurseId,
  selectedNurseId,
  nurses,
  setSelectedDate,
  selectedDate,
  setSelectedTime,
  selectedTime,
  setDesc,
}) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = React.useState("");
  useEffect(() => {
    if (value !== "") {
      const nId = parseInt(
        nurses.find((e) => `${e.firstName} ${e.lastName}` === value)?.id?.split("NU")[1],
        10
      );
      setSelectedNurseId(nId);

      setDesc(`This order has been assigned to ${value}`);
    }
  }, [value]);
  useEffect(() => {
    const temp = [];
    nurses.map((e) => temp.push(`${e.firstName} ${e.lastName}`));
    setOptions(temp);
    const currentVal = nurses.find((e) => parseInt(e?.id?.split("NU")[1], 10) === selectedNurseId);
    setInputValue(`${currentVal?.firstName} ${currentVal?.lastName}`);
    setValue(`${currentVal?.firstName} ${currentVal?.lastName}`);
  }, [nurses]);
  return (
    <div>
      <div>
        <Autocomplete
          value={value || ""}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputValue || ""}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          // className="my-4"
          id="nurseSelectionId"
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select Nurse" />}
        />
      </div>
      <div className="text-[20px] mt-2">Visit time: </div>
      <div className="w-[100%] flex mb-1">
        <div className="flex-1">
          <div>Date: </div>
          <input
            type="date"
            step="1"
            name="date"
            id="date"
            className="outline-none ml-2 border-[1px] border-gray-500 px-2 rounded-[5px]"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <div>Time: </div>
          <input
            type="time"
            step="1"
            name="time"
            id="time"
            className="outline-none ml-2 border-[1px] border-gray-500 px-2 rounded-[5px]"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
NurseSelection.propTypes = {
  setSelectedDate: PropTypes.func,
  setDesc: PropTypes.func,
  selectedDate: PropTypes.string || null,
  setSelectedTime: PropTypes.func,
  selectedTime: PropTypes.string || null,
  setSelectedNurseId: PropTypes.func,
  selectedNurseId: PropTypes.number || null,
  nurses: PropTypes.array,
};
NurseSelection.defaultProps = {
  setSelectedDate: () => {},
  setDesc: () => {},
  selectedDate: null,
  setSelectedTime: () => {},
  selectedTime: null,
  setSelectedNurseId: () => {},
  selectedNurseId: null,
  nurses: [],
};

export default NurseSelection;
