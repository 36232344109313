import * as yup from "yup";

/**
 * @function createDoctor
 * @description create an model for doctor that verify body of doctors.
 * @returns an yup Object
 */
const createDoctor = yup.object().shape({
  firstName: yup.string("first name must be string").required("first name is required"),
  lastName: yup.string("last name must be string").required("last name is required"),
  phone: yup.string("phone number must be number type").required("phone number is required"),
  officePhone: yup
    .string("office phone number must be number type")
    .required("phone number is required"),
});

export default createDoctor;
