import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Tooltip,
} from "@mui/material";
import { getAnOrderInfo } from "api/orders";
import {
  setAlertColor,
  setCircularProgress,
  setAlertText,
  setAlertOpen,
  useMaterialUIController,
  setOpenOrderItemsModal,
} from "context";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

/**
 * @component StatusModal
 * @description Component for Orders Modal.
 */
function Modal() {
  const [controller, dispatch] = useMaterialUIController();
  const { openOrderItemsModal, orderItems, darkMode } = controller;
  const [ordersDetail, setOrdersDetail] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (orderItems !== "") {
      setLoading(true);
      setCircularProgress(dispatch, true);
      getAnOrderInfo((isOk2, res2) => {
        if (!isOk2) {
          setAlertColor(dispatch, "error");
          setAlertText(dispatch, "error in giving orders detail");
          setAlertOpen(dispatch, true);
          setCircularProgress(dispatch, false);
          setLoading(false);
        } else {
          setOrdersDetail(res2.data);
          setCircularProgress(dispatch, false);
          setLoading(false);
        }
      }, orderItems);
    }
  }, [orderItems]);
  const handleClose = () => setOpenOrderItemsModal(dispatch, false);
  if (Object.keys(ordersDetail).length <= 0) return "";
  return (
    <div>
      <Dialog
        open={openOrderItemsModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ backgroundColor: darkMode ? "black" : "white" }}>
          <div className="flex">
            <div>order items: </div>
            <div className="ml-auto">
              <Icon className="cursor-pointer" onClick={handleClose}>
                close
              </Icon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: darkMode ? "black" : "white" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{ width: "500px" }}>
              {loading ? (
                <div>loading....</div>
              ) : (
                <>
                  <Grid container>
                    <Grid xs={2} className="text-[15px] text-center">
                      Order Id
                    </Grid>
                    <Grid xs={4} className="text-[15px] text-center">
                      Product Name
                    </Grid>
                    <Grid xs={2} className="text-[15px] text-center">
                      Price
                    </Grid>
                    <Grid xs={2} className="text-[15px] text-center">
                      Quantity
                    </Grid>
                    <Grid xs={2} className="text-[15px] text-center">
                      Total Price
                    </Grid>
                  </Grid>
                  <div className="w-[100%] overflow-y-auto">
                    {ordersDetail.orderItems.map((value) => (
                      <Grid container>
                        <Grid xs={2} className="text-[13px] text-center font-[600]">
                          {value.id}
                        </Grid>
                        <Tooltip title={value.productDescription}>
                          <Grid xs={4} className="text-[13px] text-center font-[600]">
                            {value.productName}
                          </Grid>
                        </Tooltip>
                        <Grid xs={2} className="text-[13px] text-center font-[600]">
                          {value.price / 100}$
                        </Grid>
                        <Grid xs={2} className="text-[13px] text-center font-[600]">
                          {value.quantity}
                        </Grid>
                        <Grid xs={2} className="text-[13px] text-center font-[600]">
                          {value.totalPrice / 100}$
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                  {ordersDetail.prescription && (
                    <div className="border-t border-t-[2px] mt-[50px] pt-[10px]">
                      <div className="font-bold text-black">Doctor&apos;s Prescription</div>
                      <Grid container>
                        <Grid xs={3} className="text-[15px] text-center">
                          Prescription Id
                        </Grid>
                        <Grid xs={2} className="text-[15px] text-center">
                          Doctor Id
                        </Grid>
                        <Grid xs={7} className="text-[15px] text-center">
                          Description
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid xs={3} className="text-[15px] text-center font-bold">
                          {ordersDetail.prescription.id}
                        </Grid>
                        <Grid xs={2} className="text-[15px] text-center font-bold">
                          {ordersDetail.prescription.doctorId}
                        </Grid>
                        <Grid xs={7} className="text-[15px] text-center font-bold">
                          {ordersDetail.prescription.description}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {ordersDetail.nurse && (
                    <div className="border-t border-t-[2px] mt-[50px] pt-[10px]">
                      <div className="font-bold text-black">Nurse&apos;s Information</div>
                      <Link
                        onClick={() => {
                          setOpenOrderItemsModal(dispatch, false);
                        }}
                        to={`/nurses/${ordersDetail.nurse?.id}`}
                      >
                        <Grid container>
                          <Grid xs={3} className="text-[15px] text-center">
                            nurse name
                          </Grid>
                          <Grid xs={2} className="text-[15px] text-center">
                            phone
                          </Grid>
                          <Grid xs={7} className="text-[15px] text-center">
                            Address
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid xs={3} className="text-[15px] text-center font-bold">
                            {ordersDetail.nurse?.firstName} {ordersDetail.nurse?.lastName}
                          </Grid>
                          <Grid xs={2} className="text-[15px] text-center font-bold">
                            {ordersDetail?.nurse.phone}
                          </Grid>
                          <Grid xs={7} className="text-[15px] text-center font-bold">
                            {ordersDetail?.nurse.state} / {ordersDetail?.nurse.city}
                          </Grid>
                        </Grid>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Modal;
