import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  setOpenDrawerRight,
  useMaterialUIController,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setRefreshClientAddress,
  setCircularProgress,
} from "context";
import MDInput from "components/MDInput";
import createClientAddress from "model/clientAddressModel";
import { postCreateClientAddress } from "api/clients";
import { useLocation } from "react-router-dom";
import DrawerRightRoot from "./DrawerRightRoot";
/**
 * @component DrawerRightForCreateClientAddress
 * @description Component for drawer right to create a new client address.
 * @returns {ReactNode} render a ui for drawer content
 * @property {string} country set local country value
 * @property {string} state set local state value
 * @property {string} city set local city value
 * @property {string} address set local address value
 * @property {string} id set client id
 */
function DrawerRightForCreateClientAddress() {
  const [controller, dispatch] = useMaterialUIController();
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const { openDrawerRight, darkMode, refreshClientAddress } = controller;
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  /**
   * @function createAdderssBtnClicked
   * @description action function for click of create client address button
   */
  const createAdderssBtnClicked = () => {
    const clientId = parseInt(id.slice(2), 10);
    const body = {
      country,
      state,
      city,
      address,
      clientId,
      // location: ["35.2225", "52.2225"],
    };
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    createClientAddress
      .validate(body, { abortEarly: false })
      .then((valid) => {
        postCreateClientAddress(
          (isOk, data) => {
            if (!isOk) {
              console.log("create Client Address information is not successful: ", data);
              setCircularProgress(dispatch, false);
              setAlertText(dispatch, data?.response?.data?.message);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "error");
            } else {
              setCircularProgress(dispatch, false);
              setRefreshClientAddress(dispatch, !refreshClientAddress);
              setCountry("");
              setState("");
              setCity("");
              setAddress("");
              setAlertText(dispatch, "create new client Address is successful!");
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "success");
            }
          },
          id,
          valid
        );
      })
      .catch((err) => {
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      });
  };
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Add a new client address
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            id="countryInput"
            type="text"
            label="Country"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={state}
            onChange={(e) => setState(e.target.value)}
            id="stateInput"
            type="text"
            label="State"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={city}
            onChange={(e) => setCity(e.target.value)}
            id="cityInput"
            type="text"
            label="City"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            id="addressInput"
            type="text"
            label="Address"
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            onClick={() => createAdderssBtnClicked()}
            variant="gradient"
            color="info"
            fullWidth
          >
            Add
          </MDButton>
        </MDBox>
      </MDBox>
    </DrawerRightRoot>
  );
}
export default DrawerRightForCreateClientAddress;
