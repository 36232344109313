import * as yup from "yup";

/**
 * @function clientModel
 * @description create an model for client that verify body of clients.
 * @returns an yup Object
 */
const clientModel = yup.object().shape({
  firstName: yup.string("first name must be string").required("first name is required"),
  lastName: yup.string("last name must be string").required("last name is required"),
  phone: yup.string("phone number must be number type").required("phone number is required"),
});

export default clientModel;
