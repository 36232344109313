import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  setOpenDrawerRight,
  useMaterialUIController,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setRefreshProducts,
  setCircularProgress,
} from "context";
import MDInput from "components/MDInput";
import createProduct from "model/productModel";
import { putEditProduct } from "api/products";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DrawerRightRoot from "./DrawerRightRoot";

/**
 * @component DrawerRightForEditProduct
 * @description Component for drawer right to edit an product informations.
 * @returns {ReactNode} render a ui for drawer content
 * @property {number} name set local name value
 * @property {firstName} description set local description value
 * @property {lastName} status set local status value
 */
function DrawerRightForEditProduct() {
  const [controller, dispatch] = useMaterialUIController();
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const { openDrawerRight, darkMode, refreshProducts, editInfo } = controller;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [status, setStatus] = useState("");
  useEffect(() => {
    setName(editInfo?.name);
    setDescription(editInfo?.description);
    setPrice(editInfo?.price);
    setStatus(editInfo?.status);
  }, [editInfo]);
  const editProductBtnClicked = () => {
    const body = {
      name,
      description,
      price,
      status,
    };
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    createProduct
      .validate(body, { abortEarly: false })
      .then((valid) => {
        putEditProduct(
          (isOk, data) => {
            if (!isOk) {
              console.log("edit Product information is not successful: ", data);
              setCircularProgress(dispatch, false);
              setAlertText(dispatch, data?.response?.data?.message);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "error");
            } else {
              setCircularProgress(dispatch, false);
              setRefreshProducts(dispatch, !refreshProducts);
              setName("");
              setDescription("");
              setPrice(0);
              setStatus("");
              setAlertText(dispatch, `edit ${editInfo?.name} information is successful!`);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "success");
            }
          },
          editInfo.id,
          valid
        );
      })
      .catch((err) => {
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      });
  };
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit {editInfo.name}&apos; information
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="nameInput"
            type="text"
            label="Name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="descInput"
            type="text"
            label="Description"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            id="priceInput"
            type="number"
            label="Price in cent"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <FormControl fullWidth>
            <InputLabel id="statuslabel">Status</InputLabel>
            <Select
              style={{ height: "50px" }}
              labelId="statusSelect-label"
              id="statusSelect"
              value={status}
              label="Status"
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="in-service">In Service</MenuItem>
              <MenuItem value="deactivated">Deactivated</MenuItem>
              <MenuItem value="out-of-service">Out Of Service</MenuItem>
            </Select>
          </FormControl>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            onClick={() => editProductBtnClicked()}
            variant="gradient"
            color="info"
            fullWidth
          >
            Edit
          </MDButton>
        </MDBox>
      </MDBox>
    </DrawerRightRoot>
  );
}
export default DrawerRightForEditProduct;
