/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import { getAllOrdersCount } from "api/orders";

import { getClientAllTransactions } from "api/transactions";
import MDBox from "components/MDBox";
import {
  setAlertColor,
  setAlertOpen,
  setAlertText,
  setCircularProgress,
  useMaterialUIController,
  setClientTab,
} from "context";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import React, { useEffect, useState } from "react";

/**
 * @component client Oveview
 * @description Component for client Oveview.
 * @returns {ReactNode} render a ui for client Oveview that renders some charts.
 * @param {number} transactionsCount all transactions count
 * @property {number} transactionsAmount client transactions amount.
 * @property {number} ordersCount all client orders count.
 */
function Oveview({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [transactionsAmount, setTransactionsAmount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAllOrdersCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give orders count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setOrdersCount(res.data);
        setCircularProgress(dispatch, false);
      }
    }, `?clientId=${id}`);
    getClientAllTransactions((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give transactions count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setTransactionsCount(res.data.count);
        setTransactionsAmount(res.data.total_amount);
        setCircularProgress(dispatch, false);
      }
    }, id);
  }, []);
  return (
    <MDBox py={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <div
              style={{ cursor: "pointer" }}
              role="presentation"
              onClick={() => setClientTab(dispatch, 3)}
            >
              <ComplexStatisticsCard
                icon="currency_exchange"
                title="Transactions"
                count={transactionsCount}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: `total amount: ${transactionsAmount / 100}$`,
                }}
              />
            </div>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <div
              style={{ cursor: "pointer" }}
              role="presentation"
              onClick={() => setClientTab(dispatch, 2)}
            >
              <ComplexStatisticsCard
                color="dark"
                icon="book_online"
                title="Orders"
                count={ordersCount}
                percentage={{
                  color: "error",
                  amount: "-33%",
                  label: `client id: ${id}`,
                }}
              />
            </div>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default Oveview;
