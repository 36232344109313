/**
 * @file context
 * @description this file to create an context for general state managment of whole project
 * @description project's states is management in this file
 */

import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "SET_ALERT_OPEN": {
      return { ...state, alertOpen: action.value };
    }
    case "SET_ALERT_TEXT": {
      return { ...state, alertText: action.value };
    }
    case "SET_ALERT_COLOR": {
      return { ...state, alertColor: action.value };
    }
    case "SET_CIRCULAR_PROGRESS_OPEN": {
      return { ...state, circularProgress: action.value };
    }
    case "SET_PRODUCTUS_DATA": {
      return { ...state, products: action.value };
    }
    case "SET_ADMINS_DATA": {
      return { ...state, admins: action.value };
    }
    case "SET_CLIENTS_DATA": {
      return { ...state, clients: action.value };
    }
    case "SET_DOCTORS_DATA": {
      return { ...state, doctors: action.value };
    }
    case "SET_NURSES_DATA": {
      return { ...state, nurses: action.value };
    }
    case "SET_OREDERS_DATA": {
      return { ...state, orders: action.value };
    }
    case "SET_PERSON_INFO": {
      return { ...state, personInfo: action.value };
    }
    case "SET_OPEN_OREDER_ITEMS_MODAL": {
      return { ...state, openOrderItemsModal: action.value };
    }
    case "SET_OREDER_ITEMS": {
      return { ...state, orderItems: action.value };
    }
    case "SET_CLIENT_ADDRESSES": {
      return { ...state, clientAddresses: action.value };
    }
    case "SET_CLIENTS_ORDERS": {
      return { ...state, clientOrders: action.value };
    }
    case "SET_CLIENTS_TRANSACTIONS": {
      return { ...state, clientTransactions: action.value };
    }
    case "SET_CLIENT_TAB": {
      return { ...state, clientTab: action.value };
    }
    case "SET_NURSE_TAB": {
      return { ...state, nurseTab: action.value };
    }
    case "SET_TRANSACTIONS_DATA": {
      return { ...state, transactions: action.value };
    }
    case "SET_OPEN_DRAWER": {
      return { ...state, openDrawerRight: action.value };
    }
    case "SET_REFRESH_CLIENTS": {
      return { ...state, refreshClients: action.value };
    }
    case "SET_REFRESH_CLIENT_ADDRESS": {
      return { ...state, refreshClientAddress: action.value };
    }
    case "SET_REFRESH_DOCTORS": {
      return { ...state, refreshDoctors: action.value };
    }
    case "SET_REFRESH_NURSES": {
      return { ...state, refreshNurses: action.value };
    }
    case "SET_REFRESH_PRODUCTS": {
      return { ...state, refreshProducts: action.value };
    }
    case "SET_ODR": {
      return { ...state, openDrawerReason: action.value };
    }
    case "SET_EDIT_INFO": {
      return { ...state, editInfo: action.value };
    }
    case "SET_OPEN_UPDATE_ORDER_STATUS_MODAL": {
      return { ...state, openUpdateOrderStatusModal: action.value };
    }
    case "SET_OREDER_FOR_UPDATE": {
      return { ...state, orderForUpdate: action.value };
    }
    case "SET_CLIENT_ID_IN_ORDER_MODAL": {
      return { ...state, clientIdInOrderModal: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    circularProgress: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    alertOpen: false,
    alertText: "",
    openUpdateOrderStatusModal: false,
    orderForUpdate: {},
    clientIdInOrderModal: "",
    alertColor: "",
    editInfo: {},
    refreshClientAddress: false,
    refreshDoctors: false,
    refreshNurses: false,
    refreshProducts: false,
    products: [],
    nurses: [],
    clientAddresses: [],
    clientOrders: [],
    clientTransactions: [],
    admins: [],
    clients: [],
    doctors: [],
    orders: [],
    transactions: [],
    openOrderItemsModal: false,
    orderItems: "",
    personInfo: {},
    clientTab: 0,
    nurseTab: 0,
    itemsPerPage: 15,
    openDrawerRight: false,
    openDrawerReason: "",
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setAlertOpen = (dispatch, value) => dispatch({ type: "SET_ALERT_OPEN", value });
const setAlertColor = (dispatch, value) => dispatch({ type: "SET_ALERT_COLOR", value });
const setAlertText = (dispatch, value) => dispatch({ type: "SET_ALERT_TEXT", value });
const setProductsData = (dispatch, value) => dispatch({ type: "SET_PRODUCTUS_DATA", value });
const setAdminsData = (dispatch, value) => dispatch({ type: "SET_ADMINS_DATA", value });
const setClientsData = (dispatch, value) => dispatch({ type: "SET_CLIENTS_DATA", value });
const setDoctorsData = (dispatch, value) => dispatch({ type: "SET_DOCTORS_DATA", value });
const setNursesData = (dispatch, value) => dispatch({ type: "SET_NURSES_DATA", value });
const setClientOrders = (dispatch, value) => dispatch({ type: "SET_CLIENTS_ORDERS", value });
const setClientTransactions = (dispatch, value) =>
  dispatch({ type: "SET_CLIENTS_TRANSACTIONS", value });
const setOrdersData = (dispatch, value) => dispatch({ type: "SET_OREDERS_DATA", value });
const setPersonInfo = (dispatch, value) => dispatch({ type: "SET_PERSON_INFO", value });
const setClientTab = (dispatch, value) => dispatch({ type: "SET_CLIENT_TAB", value });
const setNurseTab = (dispatch, value) => dispatch({ type: "SET_NURSE_TAB", value });
const setClientAddresses = (dispatch, value) => dispatch({ type: "SET_CLIENT_ADDRESSES", value });
const setOpenOrderItemsModal = (dispatch, value) =>
  dispatch({ type: "SET_OPEN_OREDER_ITEMS_MODAL", value });
const setOrderItems = (dispatch, value) => dispatch({ type: "SET_OREDER_ITEMS", value });
const setClientIdInOrderModal = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_ID_IN_ORDER_MODAL", value });
const setOpenUpdateOrderStatusModal = (dispatch, value) =>
  dispatch({ type: "SET_OPEN_UPDATE_ORDER_STATUS_MODAL", value });
const setOrderForUpdate = (dispatch, value) => dispatch({ type: "SET_OREDER_FOR_UPDATE", value });
const setTransactionsData = (dispatch, value) => dispatch({ type: "SET_TRANSACTIONS_DATA", value });
const setOpenDrawerRight = (dispatch, value) => dispatch({ type: "SET_OPEN_DRAWER", value });
const setRefreshClients = (dispatch, value) => dispatch({ type: "SET_REFRESH_CLIENTS", value });
const setRefreshNurses = (dispatch, value) => dispatch({ type: "SET_REFRESH_NURSES", value });
const setRefreshClientAddress = (dispatch, value) =>
  dispatch({ type: "SET_REFRESH_CLIENT_ADDRESS", value });
const setRefreshDoctors = (dispatch, value) => dispatch({ type: "SET_REFRESH_DOCTORS", value });
const setRefreshProducts = (dispatch, value) => dispatch({ type: "SET_REFRESH_PRODUCTS", value });
const setODR = (dispatch, value) => dispatch({ type: "SET_ODR", value });
const setEditInfo = (dispatch, value) => dispatch({ type: "SET_EDIT_INFO", value });
const setCircularProgress = (dispatch, value) =>
  dispatch({ type: "SET_CIRCULAR_PROGRESS_OPEN", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setClientOrders,
  setClientIdInOrderModal,
  setClientTransactions,
  setMiniSidenav,
  setRefreshClients,
  setRefreshNurses,
  setRefreshDoctors,
  setRefreshClientAddress,
  setRefreshProducts,
  setTransparentSidenav,
  setOpenOrderItemsModal,
  setOrderItems,
  setOpenUpdateOrderStatusModal,
  setEditInfo,
  setNursesData,
  setOrderForUpdate,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setCircularProgress,
  setFixedNavbar,
  setAlertOpen,
  setAlertText,
  setAlertColor,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setProductsData,
  setAdminsData,
  setClientsData,
  setDoctorsData,
  setOrdersData,
  setTransactionsData,
  setPersonInfo,
  setClientTab,
  setNurseTab,
  setClientAddresses,
  setOpenDrawerRight,
  setODR,
};
