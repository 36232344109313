import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";

export default function data() {
  const [controller] = useMaterialUIController();
  const { clientAddresses } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(clientAddresses);
  }, [clientAddresses]);
  const clickOnEdit = () => {
    console.log("Edit Clicked");
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      id: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.id}
        </MDBox>
      ),
      country: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.country}
        </MDBox>
      ),
      state: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.state}
        </MDBox>
      ),
      city: (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.city}
        </MDBox>
      ),
      address: (
        <MDBox color="text" lineHeight={1} textAlign="center" style={{ maxWidth: "400px" }}>
          {value.address}
        </MDBox>
      ),
      action: (
        <MDButton
          onClick={() => clickOnEdit(value.id)}
          style={{ backgroundColor: "#095F6C", color: "white", cursor: "pointer" }}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Edit
        </MDButton>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "id", accessor: "id", align: "center" },
      { Header: "country", accessor: "country", align: "center" },
      { Header: "state", accessor: "state", align: "center" },
      { Header: "city", accessor: "city", align: "center" },
      { Header: "address", accessor: "address", align: "center" },
      // { Header: "action", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}
