/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Card, Grid, Pagination } from "@mui/material";
import { getClientAddresses } from "api/clients";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  useMaterialUIController,
  setCircularProgress,
  setClientAddresses,
  setAlertColor,
  setAlertText,
  setOpenDrawerRight,
  setODR,
  setAlertOpen,
} from "context";
import DataTable from "examples/Tables/DataTable";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import authorsTableData from "./data/tableData";
// import Billing from "layouts/billing";

/**
 * @component client Addresses
 * @description Component for client Addresses Table.
 * @returns {ReactNode} render a ui for client Addresses Table.
 * @param {string} id client id value
 * @property {number} pageNumber set local pageNumber value.
 * @property {number} allPages set local allPages value.
 * @property {number} itemsPerPage items Per Page value.
 * @property {Object} columns table columns value.
 * @property {Object} rows table rows value.
 */
function Addresses({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const navigate = useNavigate();
  const { clientAddresses, refreshClientAddress } = controller;

  const [pageNumber, setPageNumber] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const { itemsPerPage } = controller;
  const { pathname } = useLocation();
  useEffect(() => {
    let url = `/clients/${id}`;
    url += `?page=${pageNumber}`;
    navigate(url);
  }, [pageNumber]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setPageNumber(1);
    else setPageNumber(parseInt(paramsObj.page, 10));
    setCircularProgress(dispatch, true);
    getClientAddresses((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give client transactions count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        if (res.count !== 0) {
          setAllPages(Math.ceil(res.count / itemsPerPage));
        }
        let param = "";
        param += `?skip=${
          (parseInt(paramsObj.page, 10) - 1) * itemsPerPage || 0
        }&limit=${itemsPerPage}`;
        getClientAddresses(
          (isOk2, res2) => {
            if (!isOk2) {
              setAlertColor(dispatch, "error");
              setAlertText(dispatch, "error in giving client transactions list");
              setAlertOpen(dispatch, true);
              setCircularProgress(dispatch, false);
            } else {
              setClientAddresses(dispatch, res2.data);
              setCircularProgress(dispatch, false);
            }
          },
          id,
          param
        );
      }
    }, id);
  }, [pathname, window.location.search, refreshClientAddress]);
  const handlePaginationChange = (event, value) => {
    let url = "";
    url += `/clients/${id}`;
    url += `?page=${value}`;
    navigate(url);
  };
  const addNewAddress = () => {
    setOpenDrawerRight(dispatch, true);
    setODR(dispatch, "addClientAddress");
  };

  if (Object.keys(clientAddresses).length === 0)
    return (
      <div className="flex flex-col mb-2 items-center h-[200px] justify-center">
        <div className="font-bold text-[20px]">No Address has been Added</div>
        <MDButton
          onClick={() => addNewAddress()}
          style={{ backgroundColor: "#095F6C", color: "white" }}
        >
          Add New Address
        </MDButton>
      </div>
    );
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <MDBox pt={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
            <Pagination
              page={pageNumber}
              onChange={handlePaginationChange}
              count={allPages}
              color="mainColor"
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Addresses;
