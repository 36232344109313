import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  setOpenDrawerRight,
  useMaterialUIController,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setRefreshDoctors,
  setCircularProgress,
} from "context";
import MDInput from "components/MDInput";
import createDoctor from "model/doctorModel";
import { putEditDoctor } from "api/doctors";
import DrawerRightRoot from "./DrawerRightRoot";

/**
 * @component DrawerRightForEditDoctor
 * @description Component for drawer right to edit an doctor informations.
 * @returns {ReactNode} render a ui for drawer content
 * @property {number} name set local name value
 * @property {firstName} description set local description value
 * @property {lastName} status set local status value
 */
function DrawerRightForEditDoctor() {
  const [controller, dispatch] = useMaterialUIController();
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const { openDrawerRight, darkMode, refreshDoctors, editInfo } = controller;
  const [number, setNumber] = useState("+1");
  const [officePhone, setOfficePhone] = useState("+1");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  useEffect(() => {
    setFirstName(editInfo?.firstName);
    setLastName(editInfo?.lastName);
    setNumber(editInfo?.phone);
    setOfficePhone(editInfo?.officePhone);
  }, [editInfo]);
  useEffect(() => {
    if (officePhone.length < 2) setOfficePhone("+1");
  }, [officePhone]);
  useEffect(() => {
    if (number.length < 2) setNumber("+1");
  }, [number]);
  const editUserBtnClicked = () => {
    const body = {
      firstName,
      lastName,
      phone: number,
      officePhone,
    };
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    createDoctor
      .validate(body, { abortEarly: false })
      .then((valid) => {
        putEditDoctor(
          (isOk, data) => {
            if (!isOk) {
              console.log("edit doctor information is not successful: ", data);
              setCircularProgress(dispatch, false);
              setAlertText(dispatch, data?.response?.data?.message);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "error");
            } else {
              setCircularProgress(dispatch, false);
              setRefreshDoctors(dispatch, !refreshDoctors);
              setLastName("");
              setFirstName("");
              setNumber("");
              setOfficePhone("");
              setAlertText(dispatch, `edit ${editInfo?.firstName} information is successful!`);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "success");
            }
          },
          editInfo.id,
          valid
        );
      })
      .catch((err) => {
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      });
  };
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit {editInfo.firstName} {editInfo.lastName}&apos; information
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            id="firstNameInput"
            type="text"
            label="First Name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            id="lastNameInput"
            type="text"
            label="Last Name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            id="phoneNumberInput"
            type="text"
            label="Phone number"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={officePhone}
            onChange={(e) => setOfficePhone(e.target.value)}
            id="officePhoneNumberInput"
            type="text"
            label="Office Phone number"
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton onClick={() => editUserBtnClicked()} variant="gradient" color="info" fullWidth>
            Edit
          </MDButton>
        </MDBox>
      </MDBox>
    </DrawerRightRoot>
  );
}
export default DrawerRightForEditDoctor;
