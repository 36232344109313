import * as yup from "yup";
/**
 * @function clientAddressModel
 * @description create an model for client addresses that verify body of client address
 * @returns an yup Object
 */
const clientAddressModel = yup.object().shape({
  country: yup.string("country must be string").required("country is required"),
  state: yup.string("state must be string").required("state is required"),
  city: yup.string("city must be string").required("city is required"),
  address: yup.string("address must be string").required("address is required"),
});

export default clientAddressModel;
