import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setAlertOpen,
  setOpenDrawerRight,
  setODR,
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { Alert, CircularProgress, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DrawerRight from "components/DrawerRight";
// import AutoLogout from "AutoLogout";

/**
 * @component App
 * @description This Component render in first time and render routing and other options.
 * @property {boolean} alertOpen a boolean for open alert box.
 * @property {boolean} circularProgress a boolean that controll if circular progress is fires or not.
 * @property {string} alertColor alert color value.
 * @property {string} alertText alert text value.
 * @property {string} darkMode dark mode variable.
 * @property {number} clientTab detail client active tab number.
 */
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    alertOpen,
    circularProgress,
    alertColor,
    alertText,
    clientTab,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setOpenDrawerRight(dispatch, false);
  }, [pathname]);
  useEffect(() => {
    setOpenDrawerRight(dispatch, false);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/authentication/sign-in");
    } else if (pathname === "/authentication/sign-in") navigate("/dashboard");
  }, [localStorage]);

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  useEffect(() => {
    if (alertOpen) {
      setTimeout(() => {
        setAlertOpen(dispatch, false);
      }, 3000);
    }
  }, [alertOpen]);
  /**
   * @function getRoutes
   * @description routing is fire in this function
   * @param {Array} allRoutes
   * @returns existing routes
   */
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  /**
   * @function handleDrawerOpen
   * @description this function controlls which drawer is open
   */
  const handleDrawerOpen = () => {
    setOpenDrawerRight(dispatch, true);
    if (pathname === "/clients") {
      setODR(dispatch, "createClient");
    } else if (pathname === "/doctors") {
      setODR(dispatch, "createDoctor");
    } else if (pathname === "/nurses") {
      setODR(dispatch, "createNurse");
    } else if (pathname === "/products") {
      setODR(dispatch, "createProduct");
    } else if (
      pathname.split("/").length === 3 &&
      pathname.split("/")[1] === "clients" &&
      clientTab === 1
    ) {
      setODR(dispatch, "addClientAddress");
    }
  };

  const addButton = (pathname === "/clients" ||
    pathname === "/doctors" ||
    pathname === "/nurses" ||
    (pathname.split("/").length === 3 && pathname.split("/")[1] === "clients" && clientTab === 1) ||
    pathname === "/products") && (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer", backgroundColor: "#095F6C" }}
      onClick={() => handleDrawerOpen()}
    >
      <Icon fontSize="small" className="text-white">
        add
      </Icon>
    </MDBox>
  );
  return (
    // <AutoLogout>
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <DrawerRight />
      <div style={{ position: "absolute", zIndex: "999999", width: "100%" }}>
        <Collapse in={alertOpen}>
          <Alert
            variant="filled"
            severity={alertColor}
            action={
              <IconButton
                aria-label="close"
                color="dark"
                size="small"
                onClick={() => {
                  setAlertOpen(dispatch, false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertText}
          </Alert>
        </Collapse>
      </div>

      {circularProgress && (
        <MDBox
          sx={{
            display: circularProgress ? "flex" : "none",
            position: "fixed",
            zIndex: 99,
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            opacity: "40%",
          }}
          style={{ backgroundColor: "black" }}
        >
          <CircularProgress color="info" />
        </MDBox>
      )}
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Nurses Admin Panel"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {addButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
    // </AutoLogout>
  );
}
