/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Card, Grid, Pagination } from "@mui/material";
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
import {
  useMaterialUIController,
  setCircularProgress,
  setAlertOpen,
  setAlertText,
  setAlertColor,
  setOrdersData,
} from "context";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllOrdersCount, getAllOrders } from "api/orders";
import Modal from "../../orders/modal";
import authorsTableData from "./data/tableData";

// import Billing from "layouts/billing";

function Transactions({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const { itemsPerPage } = controller;
  const { pathname } = useLocation();
  useEffect(() => {
    let url = `/nurses/${id}`;
    url += `?page=${pageNumber}`;
    navigate(url);
  }, [pageNumber]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setPageNumber(1);
    else setPageNumber(parseInt(paramsObj.page, 10));
    setCircularProgress(dispatch, true);
    getAllOrdersCount((isOk, res) => {
      if (!isOk) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in give orders count");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        if (res.data !== 0) {
          setAllPages(Math.ceil(res.data / itemsPerPage));
        }
        let param = "";
        param += `?nurseId=${id}&skip=${
          (parseInt(paramsObj.page, 10) - 1) * itemsPerPage
        }&limit=${itemsPerPage}`;
        getAllOrders((isOk2, res2) => {
          if (!isOk2) {
            setAlertColor(dispatch, "error");
            setAlertText(dispatch, "error in giving orders list");
            setAlertOpen(dispatch, true);
            setCircularProgress(dispatch, false);
          } else {
            setOrdersData(dispatch, res2.data);
            setCircularProgress(dispatch, false);
          }
        }, param);
      }
    }, `?nurseId=${id}`);
  }, [pathname, window.location.search]);
  const handlePaginationChange = (event, value) => {
    let url = "";
    url += `/nurses/${id}`;
    url += `?page=${value}`;
    navigate(url);
  };
  return (
    <Grid container>
      <Modal />
      <Grid item xs={12}>
        <Card>
          <MDBox pt={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
            <Pagination
              page={pageNumber}
              onChange={handlePaginationChange}
              count={allPages}
              color="mainColor"
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Transactions;
