/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Card, Grid, Pagination } from "@mui/material";
// import { getAllTransactions, getAllTransactionsCount } from "api/transactions";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../orders/modal";
import authorsTableData from "./data/tableData";

// import Billing from "layouts/billing";

function Transactions({ id }) {
  // const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const navigate = useNavigate();
  const [pageNumber] = useState(1);
  const [allPages] = useState(1);
  // const { itemsPerPage } = controller;
  // const { pathname } = useLocation();
  useEffect(() => {
    let url = `/nurses/${id}`;
    url += `?page=${pageNumber}`;
    navigate(url);
  }, [pageNumber]);
  const handlePaginationChange = (event, value) => {
    let url = "";
    url += `/nurses/${id}`;
    url += `?page=${value}`;
    navigate(url);
  };
  return (
    <Grid container>
      <Modal />
      <Grid item xs={12}>
        <Card>
          <MDBox pt={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
            <Pagination
              page={pageNumber}
              onChange={handlePaginationChange}
              count={allPages}
              color="mainColor"
            />
          </MDBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Transactions;
