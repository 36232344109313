import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getAllNurses } from "api/nurses";
import { getAllOrders, putChangeOrderStatus } from "api/orders";
import {
  setOrdersData,
  setClientOrders,
  setCircularProgress,
  setAlertColor,
  setAlertText,
  setAlertOpen,
  useMaterialUIController,
  setNursesData,
  setOpenUpdateOrderStatusModal,
} from "context";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NurseSelection from "./nurseSelection";

/**
 * @component StatusModal
 * @description Component for Orders Tables.
 * @returns {ReactNode} render a ui for nurse modal.
 * @property {number} itemsPerPage items Per Page value.
 * @property {string} currentStatus local current status of order.
 * @property {string} selectedDate local current select date of order.
 * @property {string} selectedTime local current select time of order.
 * @property {string} desc local current description of order.
 * @property {string} selectedNurseId local current select nurse id of order.
 */
function StatusModal() {
  const [controller, dispatch] = useMaterialUIController();
  const { itemsPerPage, darkMode, nurses } = controller;
  const { openUpdateOrderStatusModal, orderForUpdate, clientIdInOrderModal } = controller;
  const [currentStatus, setCurrentStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [desc, setDesc] = useState("");
  const [selectedNurseId, setSelectedNurseId] = useState(null);
  useEffect(() => {
    if (orderForUpdate.status !== "payed" && orderForUpdate.status !== "pending")
      setCurrentStatus(orderForUpdate.status);
    else setCurrentStatus(null);
    if (orderForUpdate.status === "assigned-to-nurse") {
      setSelectedNurseId(parseInt(orderForUpdate?.nurse?.id.split("NU")[1], 10));
      setSelectedDate(orderForUpdate?.visitAt.split("T")[0]);
      setSelectedTime(orderForUpdate?.visitAt.split("T")[1].split(".")[0]);
    }

    let param = "";
    param += `?limit=${200}`;
    setCircularProgress(dispatch, true);
    getAllNurses((isOk2, res2) => {
      if (!isOk2) {
        setAlertColor(dispatch, "error");
        setAlertText(dispatch, "error in giving nurses list");
        setAlertOpen(dispatch, true);
        setCircularProgress(dispatch, false);
      } else {
        setNursesData(dispatch, res2.data);
        setCircularProgress(dispatch, false);
      }
    }, param);
  }, [openUpdateOrderStatusModal]);
  useEffect(() => {
    if (currentStatus === "assigned-to-nurse")
      setDesc(
        `This order has been assigned to ${
          orderForUpdate.nurse
            ? `${orderForUpdate?.nurse?.firstName} ${orderForUpdate?.nurse?.lastName}`
            : "a certain nurse"
        }`
      );
    else if (currentStatus === "no-response-by-client")
      setDesc("The nurse visited but the client was not present");
    else if (currentStatus === "canceled") setDesc("The request has been canceld by the client");
    else if (currentStatus === "done") setDesc("The request done by a nurse");
    else setDesc("");
  }, [currentStatus]);
  const navigate = useNavigate();
  const submitButtonClicked = () => {
    if (
      currentStatus === "" &&
      (desc === "This order has been assigned to a certain nurse" ||
        desc === "The nurse visited but the client was not present" ||
        desc === "The request has been canceld by the client" ||
        desc === "The request done by a nurse" ||
        desc === "")
    )
      return;
    if (currentStatus === "assigned-to-nurse" && !selectedNurseId) {
      setAlertColor(dispatch, "warning");
      setAlertText(dispatch, "you must select the relevant nurse");
      setAlertOpen(dispatch, true);
      return;
    }
    if (!selectedDate && !selectedTime) {
      setAlertColor(dispatch, "warning");
      setAlertText(dispatch, "you must select the visit time");
      setAlertOpen(dispatch, true);
      return;
    }
    const visitDate = new Date(
      selectedDate.split("-")[0],
      selectedDate.split("-")[1] - 1,
      selectedDate.split("-")[2],
      selectedTime.split(":")[0],
      selectedTime.split(":")[1],
      selectedTime.split(":")[2]
    );
    const currentDate = new Date();
    if (visitDate < currentDate) {
      setAlertColor(dispatch, "warning");
      setAlertText(dispatch, "visit time must be in future times!");
      setAlertOpen(dispatch, true);
      return;
    }
    setOpenUpdateOrderStatusModal(dispatch, false);
    setCircularProgress(dispatch, true);
    const body = {
      status: currentStatus,
      message: desc,
    };
    if (currentStatus === "assigned-to-nurse") {
      body.nurseId = selectedNurseId;
      body.visitAt = visitDate.toISOString();
    }
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    putChangeOrderStatus(
      (isOk, res) => {
        if (false) console.log(res);
        if (!isOk) {
          console.log("get orders data from api is not working!");
          setCircularProgress(dispatch, false);
        } else if (clientIdInOrderModal === "") {
          let param = "";
          param += `?skip=${
            (parseInt(paramsObj.page, 10) - 1) * itemsPerPage
          }&limit=${itemsPerPage}`;
          getAllOrders((isOk2, data2) => {
            if (!isOk2) {
              console.log("get orders data from api is not working!");
              navigate("/orders?page=1");
              setCircularProgress(dispatch, false);
            } else {
              setCircularProgress(dispatch, false);
              setOrdersData(dispatch, data2.data);
            }
          }, param);
        } else {
          let param = "";
          param += `?clientId=${clientIdInOrderModal}&skip=${
            (parseInt(paramsObj.page, 10) - 1) * itemsPerPage
          }&limit=${itemsPerPage}`;
          getAllOrders((isOk2, data2) => {
            if (!isOk2) {
              console.log("get orders data from api is not working!");
              navigate("/orders?page=1");
              setCircularProgress(dispatch, false);
            } else {
              setCircularProgress(dispatch, false);
              if (clientIdInOrderModal === "") {
                setOrdersData(dispatch, data2.data);
              } else {
                setClientOrders(dispatch, data2.data);
              }
            }
          }, param);
        }
      },
      orderForUpdate.id,
      body
    );
  };
  const handleClose = () => setOpenUpdateOrderStatusModal(dispatch, false);
  return (
    <div>
      <Dialog
        open={openUpdateOrderStatusModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ backgroundColor: darkMode ? "black" : "white" }}>
          <div className="flex">
            <div>change order Status</div>
            <div className="ml-auto">
              <Icon className="cursor-pointer" onClick={handleClose}>
                close
              </Icon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: darkMode ? "black" : "white" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{ width: "500px" }}>
              <div className="w-[100%] overflow-y-auto">
                <div>You are changing status of order with id: {orderForUpdate.id}</div>
                <div>current status is: {orderForUpdate.status}</div>
                <FormControl fullWidth variant="outlined" style={{ margin: "20px 0" }}>
                  <InputLabel id="statusSelection">New Status</InputLabel>
                  <Select
                    labelId="statusSelection"
                    id="statusSelectionId"
                    value={currentStatus || ""}
                    style={{ height: "40px" }}
                    label="New Status"
                    onChange={(e) => setCurrentStatus(e.target.value)}
                  >
                    <MenuItem value="assigned-to-nurse">assigned to nurse</MenuItem>
                    <MenuItem value="no-response-by-client">no response by client</MenuItem>
                    <MenuItem value="canceled">canceled</MenuItem>
                    <MenuItem value="done">done</MenuItem>
                  </Select>
                </FormControl>
                {currentStatus === "assigned-to-nurse" && nurses.length > 0 && (
                  <NurseSelection
                    setDesc={setDesc}
                    setSelectedNurseId={setSelectedNurseId}
                    selectedNurseId={selectedNurseId}
                    nurses={nurses}
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                    setSelectedTime={setSelectedTime}
                    selectedTime={selectedTime}
                  />
                )}
                <TextField
                  style={{ marginTop: "15px" }}
                  label="Description"
                  id="Description"
                  fullWidth
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
                <div className="flex mt-[5px] items-center justify-center w-[100%]">
                  <Button
                    onClick={() => submitButtonClicked()}
                    style={{ color: "white" }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StatusModal;
