// import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  useMaterialUIController,
  setOrderItems,
  setOpenOrderItemsModal,
  setOpenUpdateOrderStatusModal,
  setOrderForUpdate,
  setClientIdInOrderModal,
  setClientTab,
} from "context";
import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import person from "assets/images/person.png";
import { Link } from "react-router-dom";
// import MDButton from "components/MDButton";
import { Icon } from "@mui/material";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { orders, darkMode } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(orders);
  }, [orders]);

  const getFullName = (first, last) => {
    if (first === null && last === null) return "Not defined by User";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  function Author({ name, phone, id }) {
    return (
      <Link
        to={`/clients/${id}`}
        onClick={() => setClientTab(dispatch, 2)}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <MDBox ml={2} lineHeight={1}>
          <MDTypography
            style={{ minWidth: "50px", fontSize: "10px" }}
            display="block"
            variant="button"
          >
            #{id}
          </MDTypography>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{phone}</MDTypography>
        </MDBox>
      </Link>
    );
  }
  Author.propTypes = {
    // image: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    // image: "",
    name: "",
    phone: "",
    id: "",
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "pending-to-pay":
        return "#C406D5";
      case "pending-to-dr-confirm":
        return "#ECBE08";
      case "rejected-by-dr":
        return "#EAA107";
      case "payed":
        return "#F44335";
      case "assigned-to-nurse":
        return "#05E7C4";
      case "no-response-by-client":
        return "#058BE9";
      case "canceled":
        return "#058BE9";
      case "done":
        return "#054701";
      default:
        return "black";
    }
  };
  const itemsClicked = (id) => {
    setOpenOrderItemsModal(dispatch, true);
    setOrderItems(dispatch, id);
  };
  const changeStatus = (order, status) => {
    if (status === "pending-to-dr-confirm" || status === "rejected-by-dr") return;
    setOpenUpdateOrderStatusModal(dispatch, true);
    setClientIdInOrderModal(dispatch, "");
    setOrderForUpdate(dispatch, order);
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      "client name": (
        <Author
          name={getFullName(value.client.firstName, value.client.lastName)}
          phone={value.client.phone}
          id={value.client.id}
        />
      ),
      "order id": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.id}
        </MDBox>
      ),
      status: (
        <MDBox
          lineHeight={1}
          textAlign="center"
          style={{
            color: darkMode ? "white" : getStatusColor(value.status),
            backgroundColor: darkMode ? getStatusColor(value.status) : "white",
            border: `1px solid ${getStatusColor(value.status)}`,
            borderRadius: "10px",
            padding: "3px 10px",
          }}
        >
          {value.status !== "assigned-to-nurse"
            ? value?.status?.replaceAll("-", " ")
            : "assigned to nurse"}
        </MDBox>
      ),
      "total price": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.totalPrice / 100}$
        </MDBox>
      ),
      "visit at": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.visitAt.split("T")[0]}
        </MDBox>
      ),
      "created at": (
        <MDBox color="text" lineHeight={1} textAlign="center">
          {value.createdAt.split("T")[0]}
        </MDBox>
      ),
      address: (
        <MDBox lineHeight={1} textAlign="center" style={{ maxWidth: "300px" }}>
          <MDBox color="text" style={{ fontWeight: "700" }}>
            {value.address.country}/{value.address.city}
          </MDBox>
          <MDBox color="text" style={{ fontSize: "10px", lineHeight: "15px" }}>
            {value.address.address || "no address"}
          </MDBox>
        </MDBox>
      ),
      action: (
        <MDBox>
          <MDBox color="text">
            <Icon
              fontSize="small"
              className="cursor-pointer"
              onClick={() => itemsClicked(value.id)}
            >
              visibility
            </Icon>
          </MDBox>
          <MDBox color="text">
            <Icon
              className={`${
                value.status === "pending-to-dr-confirm" || value.status === "rejected-by-dr"
                  ? "text-gray-300"
                  : "cursor-pointer"
              }`}
              fontSize="small"
              onClick={() => changeStatus(value, value.status)}
            >
              change_circle
            </Icon>
          </MDBox>
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "action", accessor: "action", align: "center" },
      { Header: "order id", accessor: "order id", align: "center" },
      { Header: "client name", accessor: "client name", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "total price", accessor: "total price", align: "center" },
      { Header: "visit at", accessor: "visit at", align: "center" },
      { Header: "created at", accessor: "created at", align: "center" },
      { Header: "address", accessor: "address", align: "center" },
    ],

    rows: getRows(),
  };
}
