import { AxiosInstance } from "./api";
/**
 * @function loginToDashboard
 * @description for login to dashboard based on username and password that comes in body
 * @param {Function} callback this function used for return response data from api
 * @param {Object} body this value includes body values(username and password)
 */
const loginToDashboard = (callback, body) => {
  AxiosInstance()
    .post("/api/v1/auth/login-admin", body)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export default loginToDashboard;
