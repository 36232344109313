import axios from "axios";

// if we dont need to x-auth-token
/**
 * @function AxiosInstance
 * @description created an axios instancs for api's without token parameter
 * @returns an axios instance
 */
export const AxiosInstance = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });

// if we needs to x-auth-token
/**
 * @function AxiosInstanceWithXAuthToken
 * @description created an axios instancs for api's with token parameter
 * @returns an axios instance
 */
export const AxiosInstanceWithXAuthToken = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `${localStorage.getItem("x-auth-token")}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
