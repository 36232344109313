import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMaterialUIController, setAlertColor, setAlertText, setAlertOpen } from "context";

import loginToDashboard from "api/auth";
import { useNavigate } from "react-router-dom";
import SignInSchema from "../../../model/signIn";

/**
 * @component Basic Sign in
 * @description Component for Sign in to admin panel.
 * @returns {ReactNode} render a ui for Admins Table.
 * @property {boolean} rememberMe set local rememberMe value.
 * @property {string} userName set local userName value.
 * @property {string} password local password value.
 */
function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  if (false) console.log(controller);
  /**
   * @function signInClicked
   * @description this function handle click event for sign in button
   */
  const signInClicked = () => {
    const body = {
      username: userName,
      password,
    };
    SignInSchema.validate(body, { abortEarly: false })
      .then((valid) => {
        loginToDashboard((isOk, res) => {
          if (!isOk) {
            setAlertText(dispatch, `An error Occoured: ${res?.response?.data?.message}`);
            setAlertOpen(dispatch, true);
            setAlertColor(dispatch, "warning");
          } else {
            setAlertColor(dispatch, "success");
            setAlertText(dispatch, "Login Successfully!");
            setAlertOpen(dispatch, true);
            localStorage.setItem("x-auth-token", res.data.token);
            navigate("/dashboard");
          }
        }, valid);
      })
      .catch((err) => {
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "warning");
      });
  };

  return (
    <BasicLayout image="https://source.unsplash.com/random/1800x800?medical">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            onChange={(e) => setUserName(e.target.value)}
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
          >
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                type="text"
                label="Username"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                label="Password"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={() => {
                  signInClicked();
                }}
                variant="gradient"
                color="info"
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
